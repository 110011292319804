import {WindowSizes} from './enums/window.sizes';
import {ScrollSections} from './scroll-sections';

function getDiffCeil(a: number, downscale = false) {
  return downscale ? a - Math.floor(a) : Math.ceil(a) - a;
}

/**
 * Adds a scroll event listener that will on scroll stop (when the user has stopped scrolling)
 * scroll the section to be fully aligned on the screen without forcing user.
 */
export function alignSectionOnscrollStop(
  sections: ScrollSections, scrollToFn: Function, delay = 500,
) {
  const threshold = {
    sm: {up: .2, down: .2},
    lg: {up: .1, down: .1},
  };
  const isMobile = window.innerWidth < WindowSizes.sm;
  const percentUp = isMobile ? threshold.sm.up : threshold.lg.up;
  const percentDown = isMobile ? threshold.sm.down : threshold.lg.down;
  const directionDelay = 50;
  let isScrollingDelay: any;
  let isScrolling: any;
  let scrollPos = 0;

  document.addEventListener('scroll', () => {
    const currentScroll = window.pageXOffset
      || document.documentElement.scrollTop
      || document.body.scrollTop;
    let direction: 'up' | 'down';
    if (currentScroll < scrollPos) {
      direction = 'up';
    } else {
      direction = 'down';
    }

    if (isScrolling) {
      clearInterval(isScrolling);
    }
    if (isScrollingDelay) {
      clearTimeout(isScrollingDelay);
    }
    isScrolling = setTimeout(() => {
      scrollPos = currentScroll;
    }, directionDelay);
    isScrollingDelay = setTimeout(() => {
      const section = sections.getCurrentSection(false);
      if (getDiffCeil(section) === 0) {
        return;
      }
      const floor = Math.floor(section);
      const ceil = Math.ceil(section);
      let index = ceil;

      if (direction === 'down') {
        index = getDiffCeil(section, true) > percentDown ? ceil : floor;
      } else {
        index = getDiffCeil(section) > percentUp ? floor : ceil;
      }
      scrollToFn(sections.getSectionByIndex(index).id);
    }, delay);
  });
}
