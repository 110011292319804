import 'nodelist-foreach-polyfill';
import './polyfil/closest.polyfill';
import {WindowSizes} from './enums/window.sizes';
import {alignSectionOnscrollStop} from './align-sections-on-scroll-stop';
import {ScrollSections} from './scroll-sections';
import {SmoothScroll} from './smooth-scroll/smooth-scroll';
import {Tilt} from './tilt-elment/tilt.class';

const mobileMaxWindowWidth = WindowSizes.sm;
let nextNavButton: HTMLElement;
let prevNavButton: HTMLElement;
let sectionScroller: ScrollSections;
let smoothScroll: SmoothScroll;

document.addEventListener('DOMContentLoaded', () => {
  document.body.classList.add('loaded');
  const hash = window.location.hash;
  const scrollStopDetectionDelay = 250;
  sectionScroller = new ScrollSections('.section', null, toggleNavAvailability);
  smoothScroll = new SmoothScroll();
  nextNavButton = document.querySelector('[data-section="next"]');
  prevNavButton = document.querySelector('[data-section="prev"]');
  addClickHandlers();
  alignSectionOnscrollStop(sectionScroller, scrollToSection, scrollStopDetectionDelay);
  toggleNavAvailability(sectionScroller.getCurrentSection());

  if (hash) {
    const firstScrollDuration = 50;
    scrollToSection(hash.replace(/^#!/, ''), firstScrollDuration);
  }

  if (window.innerWidth > mobileMaxWindowWidth) {
    const sections = sectionScroller.sections;
    sections.forEach((element: HTMLElement) => new Tilt(element, '.section__title'));

    document.addEventListener('keydown', (event) => {
      const keyName = event.key;

      if (keyName === 'ArrowLeft') {
        navigateToSection('prev');
      }
      if (keyName === 'ArrowRight') {
        navigateToSection('next');
      }
    });
  }
});

function scrollToSection(section: string | number, duration = 300) {
  const index: number = typeof section === 'string'
    ? sectionScroller.getSectionById(section).index
    : section;
  toggleNavAvailability(index);
  smoothScroll.scrollTo(sectionScroller.getSectionVirtualPosition(section), duration);
}

function anchorLinkClickHandler(event: MouseEvent) {
  const target = (<HTMLElement>event.target).closest('a[href^="#"]');
  if (!target) {
    return;
  }
  event.preventDefault();
  const href = target.getAttribute('href');
  history.pushState(null, null, href.replace(/^#/, '#!'));
  scrollToSection(href.replace(/^[#]/, ''));
}

function navigateToSection(direction: 'next' | 'prev') {
  const currentSection = sectionScroller.getCurrentSection();
  let goToSectionIndex = currentSection;
  if (direction === 'next' && currentSection < sectionScroller.length - 1) {
    goToSectionIndex += 1;
  }
  if (direction === 'prev' && currentSection > 0) {
    goToSectionIndex -= 1;
  }
  scrollToSection(goToSectionIndex);
}

function sectionNavClickHandler(event: MouseEvent) {
  const target = (<HTMLElement>event.target).closest('[data-section]');
  if (!target) {
    return;
  }
  const direction: 'next' | 'prev' = <any>target.getAttribute('data-section');
  navigateToSection(direction);
}

function addClickHandlers() {
  document.addEventListener('click', (event) => {
    anchorLinkClickHandler(event);
    sectionNavClickHandler(event);
  });
}

function toggleNavAvailability(activeSection: number) {
  if (activeSection === 0) {
    prevNavButton.classList.add('hidden');
  } else {
    prevNavButton.classList.remove('hidden');
  }
  if (activeSection === sectionScroller.length - 1) {
    nextNavButton.classList.add('hidden');
  } else {
    nextNavButton.classList.remove('hidden');
  }
}
