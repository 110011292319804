import {reduce, has} from 'lodash';
import {WindowSizes} from '../enums/window.sizes';
import {SectionsIndexedInterface} from './sections-indexed.interface';
import {IndexedSectionInterface} from './indexed-section.interface';

export class ScrollSections {
  readonly sections: NodeListOf<HTMLElement>;
  readonly sectionsIndexed: SectionsIndexedInterface;
  readonly ease: number;
  private body: HTMLElement;
  private windowHeight: number;
  private windowWidth: number;
  private currentScroll: number = 0;
  private targetScroll: number = 0;
  private rafActive: boolean;
  private rafId: number;
  private sidePadding: number;
  private currentSection: number = 0;
  private readonly onScrollEndCallback: Function;
  sectionHeight: number;

  constructor(sectionSelector: string, ease?: number, onScrollEndCallback?: Function) {
    const defaultEaseValue = 0.1;
    this.ease = ease || defaultEaseValue;
    this.body = document.body;
    this.onScrollEndCallback = onScrollEndCallback;
    this.sections = document.querySelectorAll(sectionSelector);
    this.sectionsIndexed = ScrollSections.getIndexedSections(this.sections);
    this.sectionHeight = this.getSectionByIndex(0).element.offsetHeight;

    this.prepareSize();
    this.addListeners();
  }

  static getElementNode(nodeList: NodeList, index: number): HTMLElement {
    return nodeList[index] as HTMLElement;
  }

  static getIndexedSections(sections: NodeList): SectionsIndexedInterface {
    return reduce(sections,
      (accum: SectionsIndexedInterface, element: HTMLElement, index: number) => {
        const elementId = element.getAttribute('id');
        if (!elementId) {
          return accum;
        }
        const section = {
          index,
          element,
          id: elementId,
        };
        accum[elementId] = section;
        accum[index] = section;
        return accum;
      },
      {});
  }

  private addListeners() {
    let isScrolling: any;
    const scrollEndDetectionDelay = 100;
    window.addEventListener('scroll', () => {
      if (isScrolling) {
        clearTimeout(isScrolling);
      }
      this.updateScroll();
      isScrolling = setTimeout(() => {
        this.setCurrentSection();
        if (typeof this.onScrollEndCallback === 'function') {
          this.onScrollEndCallback(this.currentSection);
        }
      }, scrollEndDetectionDelay);
    }, false);

    if (this.windowWidth > WindowSizes.sm) {
      window.addEventListener('resize', () => {
        this.prepareSize();
      }, false);
    } else {
      window.addEventListener('orientationchange', () => {
        this.prepareSize();
      });
    }
  }

  private prepareSize() {
    this.windowHeight = window.innerHeight;
    this.windowWidth = window.innerWidth;
    // tslint:disable-next-line:no-magic-numbers
    this.sidePadding = this.windowWidth < WindowSizes.sm ? 0 : this.windowWidth * .2;

    const scrollHeight = ScrollSections.getElementNode(this.sections, 0)
      .offsetHeight * (this.sections.length - 1) + this.windowHeight;
    this.body.style.height = `${scrollHeight}px`;
    this.updateAnimation();
  }

  private updateScroll() {
    this.targetScroll = window.scrollY || window.pageYOffset;
    this.startAnimation();
  }

  private setCurrentSection(round = true) {
    let section: number;
    if (this.currentScroll === 0) {
      section = 0;
    } else {
      section = parseFloat((this.targetScroll / this.sectionHeight).toFixed(2));
    }
    this.currentSection = Math.round(section);
    this.sections.forEach((item) => {
      item.classList.remove('section--active');
    });

    this.sections[this.currentSection].classList.add('section--active');
    return round ? this.currentSection : section;
  }

  private startAnimation() {
    if (!this.rafActive) {
      this.rafActive = true;
      this.rafId = requestAnimationFrame(() => {
        this.updateAnimation();
      });
    }
  }

  private updateAnimation() {
    // Difference between `this.targetScroll` and `currentScroll` scroll position
    const diff = this.targetScroll - this.currentScroll;
    // `delta` is the value for adding to the `this.currentScroll` scroll position
    // If `diff < 0.1`, make `delta = 0`, so the animation would not be endless

    // tslint:disable-next-line:no-magic-numbers
    const delta: number = Math.abs(diff) < 0.1 ? 0 : diff * this.ease;

    if (delta) { // If `delta !== 0`
      // Update `this.currentScroll` scroll position
      this.currentScroll += delta;
      // Round value for better performance
      this.currentScroll = parseFloat(this.currentScroll.toFixed(2));
      // Call `update` again, using `requestAnimationFrame`
      this.rafId = requestAnimationFrame(() => {
        this.updateAnimation();
      });
    } else { // If `delta === 0`
      // Update `this.currentScroll`, and finish the animation loop
      this.currentScroll = this.targetScroll;
      this.rafActive = false;
      cancelAnimationFrame(this.rafId);
    }
    this.updateElementPosition(this.currentScroll, delta);
  }

  private updateElementPosition(scrollTop: number, delta: number) {
    const hundred = 100;
    const minOpacity = .6;
    const maxOpacity = 1;
    const minScale = .9;
    const maxScale = 1;
    const dsp = 40;
    const dop = 30;
    const distanceScale = Math.round(this.windowHeight * dsp / hundred);
    const distanceOpacity = Math.round(this.windowHeight * dop / hundred);

    this.sections.forEach((element: HTMLElement, index: number) => {
      const top = scrollTop - element.offsetHeight * index;
      const bottom = top + element.offsetHeight;
      let translateX: number;
      let translateY: number;
      let opacity: number = minOpacity;
      let scale: number = minScale;

      translateY = -top + delta * top / this.windowHeight;
      translateX = -(this.windowWidth + this.sidePadding) * (top / this.windowHeight)
        + delta * top / this.windowWidth;

      // ELEMENT IS ENTERING IN BOTTOM
      // debug(element.getAttribute('id'), 'top', top);
      if (bottom >= 0 && bottom < distanceScale) {
        scale = bottom / distanceScale * (maxScale - minScale) + minScale;
      }

      if (bottom >= 0 && bottom < distanceOpacity) {
        opacity = bottom / distanceOpacity * (maxOpacity - minOpacity) + minOpacity;
      }

      if (bottom >= distanceScale || top >= 0) {
        scale = maxScale;
      }

      if (bottom >= distanceOpacity || top >= 0) {
        opacity = maxOpacity;
      }

      // ELEMENT LEAVING ON TOP
      if (top > 0 && top > -element.offsetHeight) {
        scale = maxScale - (top / distanceScale * (maxScale - minScale));
        opacity = maxOpacity - (top / distanceOpacity * (maxOpacity - minOpacity));
      }

      element.style.transform = `translate3d(${translateX}px, ${translateY}px, 0) scale(${scale})`;
      element.style.opacity = `${opacity}`;
    });
  }

  private getSection(id: number | string): IndexedSectionInterface {
    if (has(this.sectionsIndexed, id)) {
      return this.sectionsIndexed[id];
    }
    return null;
  }

  get length() {
    return this.sections.length;
  }

  getSectionVirtualPosition(id: string | number): number {
    const section = this.getSection(id);
    if (section) {
      return section.element.offsetHeight * section.index;
    }
    return 0;
  }

  getSectionById(id: string): IndexedSectionInterface {
    return this.getSection(id);
  }

  getSectionByIndex(index: number): IndexedSectionInterface {
    return this.getSection(index);
  }

  getCurrentSection(round = true): number {
    const index = this.setCurrentSection(round);
    return round ? this.currentSection : index;
  }
}
