import {TweenMax} from 'gsap';

// Init events:
// Mouse events for mousemove/tilt/scale on the current image, and window resize.
export class Tilt {
  private readonly element: HTMLElement;
  private readonly tiltedElement: HTMLElement;
  private mouseleaveFn: EventListener;
  private mousemoveFn: EventListener;

  constructor(element: HTMLElement, tiltedSelector: string) {
    this.element = element.querySelector('.container');
    this.tiltedElement = this.element.querySelector(tiltedSelector);
    this.start();
  }

  private initEvents() {
    this.mousemoveFn = (ev: MouseEvent) => requestAnimationFrame(() => {
      this.tilt(ev);
    });
    this.mouseleaveFn = (ev) => requestAnimationFrame(() => {
      this.resetTilt();
    });
    this.element.addEventListener('mousemove', this.mousemoveFn);
    this.element.addEventListener('mouseleave', this.mouseleaveFn);
  }

  // Get the mouse position.
  getMousePos(e: MouseEvent) {
    return {x: e.clientX, y: e.clientY};
  }

  tilt(ev: MouseEvent) {
    const mousePosition = this.getMousePos(ev);
    const bounds = this.element.getBoundingClientRect();
    // Mouse position relative to the main element (this.DOM.el).
    const relativeMousePosition = {
      x: mousePosition.x - bounds.left,
      y: mousePosition.y - bounds.top
    };

    // Move the element from -20 to 20 pixels in both x and y axis.
    // Rotate the element from -15 to 15 degrees in both x and y axis.
    let t = {x: [-20, 20], y: [-20, 20]};

    const transforms = {
      translation: {
        x: (t.x[1] - t.x[0]) / bounds.width * relativeMousePosition.x + t.x[0],
        y: (t.y[1] - t.y[0]) / bounds.height * relativeMousePosition.y + t.y[0]
      }
    };

    // Move the texts wrap.
    TweenMax.to(this.tiltedElement, 1.5, {
      ease: 'Power1.easeOut',
      x: -1 * transforms.translation.x,
      y: -1 * transforms.translation.y
    });
  }

  /**
   * Reset tilt transformation.
   */
  private resetTilt() {
    TweenMax.to([this.tiltedElement], 1.8, {
      ease: 'Power4.easeOut',
      x: 0,
      y: 0,
      rotationX: 0,
      rotationY: 0
    });
  }

  start() {
    this.initEvents();
  }

  remove() {
    this.element.removeEventListener('mousemove', this.mousemoveFn);
    this.element.removeEventListener('mouseleave', this.mouseleaveFn);
    this.resetTilt();
  }
}
