/**
 * Allows scroll page to a position smoothly using easing function
 */
export class SmoothScroll {
  readonly element: HTMLElement;

  constructor() {
    this.element = document.querySelector('html');
  }

  /**
   * @param currentTime: current time/current step
   * @param startValue: starting position
   * @param changeInValue: amount of change (current - start)
   * @param duration: total animation time/steps
   */
  static easeInOutCubicTime(
    currentTime: number, startValue: number, changeInValue: number, duration: number,
  ) {
    const n = 2;
    currentTime /= duration / n;
    if (currentTime < 1) {
      return changeInValue / n * currentTime * currentTime * currentTime + startValue;
    }
    return changeInValue / n * ((currentTime -= n) * currentTime * currentTime + n) + startValue;
  }

  /**
   * Smooth Scroll
   * @param scrollPosition to scroll to in pixels
   * @param duration in ms
   * @param easing is the ease function, if nt provided default is used
   */
  scrollTo(
    scrollPosition: number, duration: number, easing: Function = SmoothScroll.easeInOutCubicTime,
  ) {
    const element = this.element;
    const start = element.scrollTop;
    const change = scrollPosition - start;
    const startTime = new Date().getTime();

    const animateScroll = () => {
      const currentTime = new Date().getTime() - startTime;
      element.scrollTop = easing(currentTime, start, change, duration);
      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      }
    };
    requestAnimationFrame(animateScroll);
  }
}
